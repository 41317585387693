.image-container {
	position: relative;
	text-align: center;

	.image {
		margin-bottom: 1px;
	}

	.image-footer {
		width: 100%;
		position: absolute;
		bottom: 0;

		.image-description {
			background-color: rgba(0, 0, 0, 0.8);
			color: #ffffff;
		}

		.actions-wrapper {
			left: 0;

			.btn {
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				border-radius: 0;
				&:first-child {
					border-bottom-left-radius: 4px;
				}
				&:last-child {
					border-bottom-right-radius: 4px;
				}
			}
		}
	}
}

.lazy-load-container {
	height: 200px;
	overflow: hidden;
}

.image-cells-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.selected-image {
	position: absolute;
	top: calc(50% - 25px);
	left: calc(50% - 25px);
}
