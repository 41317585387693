@media (max-width: 800px) {
	.my-masonry-grid {
		margin-left: -15px; /* gutter size offset */
	}
}

.my-masonry-grid {
	display: -webkit-box; /* Not needed if autoprefixing */
	display: -ms-flexbox; /* Not needed if autoprefixing */
	display: flex;
	//margin-left: 40px; /* gutter size offset */
	margin-top: 20px; /* gutter size offset */
	width: auto;
	height: auto;
}
.my-masonry-grid_column {
	margin-left: -30px;
	padding-left: 50px; /* gutter size */
	background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
	/* change div to reference your elements you put in <Masonry> */
	background: grey;
	margin-bottom: 50px;
}
